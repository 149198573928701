import React from 'react'

import { RenderCampaigns } from '../local_modules/components/sections/Campaigns/RenderCampaigns'
import { useLandingPageCms } from '../local_modules/hooks/useLandingPageCms'
import { CampaignsResponse } from '../local_modules/types/Campaigns'

import '../styles/pages/clube.scss'

export default function Clube() {
  const { cmsData, loading } = useLandingPageCms('clube')

  return (
    <div className="container-club">
      <RenderCampaigns
        segment="traditional"
        cmsDataLandingPage={cmsData as CampaignsResponse}
        loading={loading}
      />
    </div>
  )
}
